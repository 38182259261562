.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  flex: 1;
  /* background-color: #282c34; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(18px + 2vmin);
  color: rgb(0, 0, 0);
}

/* h1 {
  margin-bottom: 20px;
} */
